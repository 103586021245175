<template>
  <v-container>
    <!-- Header -->
    <v-form
      ref="form"
      @submit.prevent="createDisbursement"
    >
      <v-col
        align="start"
        justify="center"
        class="pa-0 mb-5"
      >
        <v-icon
          color="primary"
          style="cursor: pointer"
          @click="$router.go(-1)"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <h3
          style="display: inline-block"
          class="ms-4"
        >
          Create Disbursement
        </h3>
      </v-col>

      <!-- Toolbar -->
      <v-row class="mb-6">
        <v-col
          cols="12"
          sm="6"
          md="2"
        >
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date"
                outlined
                single-line
                hide-details
                :prepend-inner-icon="icons.mdiCalendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menuDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="2">
          <v-menu
            v-model="menuTime"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Time"
                outlined
                single-line
                hide-details
                :prepend-inner-icon="icons.mdiCalendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="time"
              class="mt-4"
              format="24hr"
              hide-details
              dense
              outlined
              @input="menuTime=false"
            ></v-time-picker>
          </v-menu>
        </v-col> -->

        <v-col
          cols="12"
          sm="6"
          md="3"
          xl="2"
        >
          <v-menu
            ref="menu"
            v-model="menuTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{}">
              <v-combobox
                v-model="time"
                :items="timeOptions"
                :prepend-inner-icon="icons.mdiClockTimeFourOutline"
                hide-details
                dense
                outlined
              ></v-combobox>
            </template>
          </v-menu>
        </v-col>
        <v-spacer />

        <!-- Search -->
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="Search employee"
            single-line
            hide-details
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="auto"
        >
          <v-btn
            class="text-capitalize"
            color="primary"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="disableDisburse"
            :loading="loading"
            type="submit"
          >
            <!-- @click="createDisbursement()" -->
            Disburse
          </v-btn>
        </v-col>
      </v-row>

      <tiptap-vuetify
        v-model="disbursementInfo"
        :extensions="extensions"
        class="mb-8"
      />

      <!-- Table -->
      <!-- <pre>{{employeeList}}</pre> -->
      <v-data-table
        :headers="headers"
        :items="employeeList"
        :items-per-page="10"
        :search="search"
      >
        <!-- name -->
        <template v-slot:item.name="{ item }">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">
              {{ item.name }}
            </div>
            <div>{{ item.phoneNumber }}</div>
          </div>
        </template>

        <template v-slot:item.paymentMethod="{ item }">
          <div class="py-4 payment-method">
            <v-select
              v-model="item.paymentMethod"
              hide-details
              :items="item.employeePhotos.length ? paymentMethodOptionsWithFaceRecognition : paymentMethodOptions"
              item-text="text"
              item-value="value"
              dense
              outlined
              :disabled="!employeeGroup.setting.cashier.status && (!item.bankAccount || !item.accountNumber)"
            ></v-select>
          </div>
        </template>

        <template v-slot:item.bankInfo="{ item }">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">
              {{
                item.paymentMethod === 'cashier-pin' || item.paymentMethod === 'cashier-face'
                  ? employeeGroup.setting.cashier.bank_account.name
                  : item.bankAccount
                    ? item.bankAccount
                    : '--'
              }}
            </div>
            <div>
              {{
                item.paymentMethod === 'cashier-pin' || item.paymentMethod === 'cashier-face'
                  ? employeeGroup.setting.cashier.account_number
                  : item.accountNumber
                    ? item.accountNumber
                    : '--'
              }}
            </div>
          </div>
        </template>

        <template v-slot:item.amount="{ item }">
          <div class="d-flex align-center justify-end">
            <v-text-field
              v-model="item.amount"
              label="Amount disbursement"
              single-line
              hide-details
              outlined
              dense
              type="number"
              :disabled="!employeeGroup.setting.cashier.status && (!item.bankAccount || !item.accountNumber)"
            />
            <!-- informasi dari mas edu amount disbursement engga required, makanya rulesnya gue matiin -->
            <!-- :rules="disbursementAmountRules" -->
            <!-- @keyup="checkDisburseAmount()" -->
            <!-- :rules="[v => v > 0 || '']" -->
          </div>
        </template>
      </v-data-table>
    </v-form>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Image,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiChevronLeft,
  mdiCalendar,
  mdiClockTimeFourOutline,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  name: 'CreateDisbursement',
  components: { TiptapVuetify },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Payment method', value: 'paymentMethod' },
        { text: 'Bank info', value: 'bankInfo' },
        { text: 'Amount', align: 'end', value: 'amount' },
      ],
      isDark,
      search,

      // icons
      icons: {
        mdiChevronLeft,
        mdiCalendar,
        mdiClockTimeFourOutline,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      menuDate: false,
      time: { text: this.$t('broadcasts.today09am'), value: '09:00' },

      // time: "12:00",
      menuTime: false,
      loading: false,
      disableDisburse: null,
      paymentMethod: 'transferBank',
      timeToSend: [],
      executionType: { text: this.$t('broadcasts.today09am'), value: '09:00' },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Image,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      disbursementInfo: '',

      // {R U L E S} //
      disbursementAmountRules: [value => !!value || 'Jumlah disbursement wajib diisi'],

      /// /////////////
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    employeeGroup() {
      return this.$store.getters['manageSalary/getCurrentEmployeeGroup']
    },
    paymentMethodOptions() {
      if (this.employeeGroup.setting.cashier.status) {
        return [
          { text: 'Transfer Bank', value: 'transferBank' },
          { text: 'Cashier With Pin', value: 'cashier-pin' },
        ]
      }

      return [{ text: 'Transfer Bank', value: 'transferBank' }]
    },
    paymentMethodOptionsWithFaceRecognition() {
      return [
        { text: 'Transfer Bank', value: 'transferBank' },
        { text: 'Cashier With Pin', value: 'cashier-pin' },
        { text: 'Cashier With Face Recognition', value: 'cashier-face' },
      ]
    },
    employeeList() {
      return this.$store.getters['manageSalary/getEmployeesFromEmployeeGroup'].map(employee => ({
        ...employee,
        // eslint-disable-next-line no-nested-ternary
        paymentMethod: employee.bankAccount
          ? 'transferBank'
          : this.employeeGroup.setting.cashier.status
          ? 'cashier'
          : 'transferBank',
        amount: '',
      }))
    },
    timeOptions() {
      return [
        { text: this.$t('broadcasts.today09am'), value: '09:00' },
        { text: this.$t('broadcasts.noon01pm'), value: '13:00' },
        { text: this.$t('broadcasts.afternoon04pm'), value: '16:00' },
      ]
    },
  },
  created() {
    this.checkDisburseAmount()
  },
  methods: {
    async createDisbursement() {
      const result = this.$refs.form.validate()
      if (!result) return

      // this.loading = true
      const disbursementData = {
        disbursementDate: new Date(`${this.date} ${this.time.value}`).getTime(),

        // disbursementDate: new Date(`${this.date} ${this.time}`).getTime(),
        employeeGroupId: this.$route.params.employeeGroupId,
        employees: this.employeeList.filter(e => e.amount !== 0),
        information: this.disbursementInfo,
      }

      // //console.log(disbursementData)

      await this.$store.dispatch('manageSalary/createDisbursement', {
        sub_id: this.user.sub_id,
        ...disbursementData,
        creator: this.user,
      })

      this.loading = false
      this.$router.push(`/manage-salary/employee-group/${this.$route.params.employeeGroupId}`)

      await this.$store.dispatch('manageApproval/getDisbursementsNeedApproval', { user: this.user })
    },
    async getEmployeeLoanSubmissionsWithNetOff() {
      await this.$store.dispatch('manageLoan/getEmployeeLoanSubmissionsWithNetOff', {
        sub_id: this.user.sub_id,
        email: this.currentEmployeeData.email,
      })
    },
    checkDisburseAmount() {
      const validEmployee = this.employeeList.filter(
        e => !(!e.bankAccount && !e.accountNumber && e.paymentMethod !== 'cashier'),
      )
      // eslint-disable-next-line eqeqeq
      this.disableDisburse = validEmployee.length === 0
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-method {
  max-width: 250px;
}
</style>
